import React, { useState } from 'react';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';

import WeeklySchedule from '../components/WeeklySchedule';
import ClosedDayLabel from '../components/ClosedDayLabel';
import AccessInfoArea from '../components/AccessInfoArea';
import Modal from '../components/Modal';

import us1lg from '../assets/img/us-1-lg.png';

const About = () => {
  const { size } = useTrackedState();
  const [open, setOpen] = useState(false);
  return (
    <SubLayout topTitle="プライバシーポリシー">
      <div>
        <P>
          当院は信頼の医療に向けて、患者さんに良い医療を受けていただけるよう日々努力を重ねております。「患者さんの個人情報」につきましても適切に保護し管理することが非常に重要であると考えております。そのために当院では、以下の個人情報保護方針を定め確実な履行に努めます。
        </P>
        <h3>１．個人情報の収集について</h3>
        <P>
          当院が患者さんの個人情報を収集する場合、診療・看護および患者さんの医療にかかわる範囲で行います。その他の目的に個人情報を利用する場合は利用目的を、あらかじめお知らせし、ご了解を得た上で実施いたします。ウエブサイトで個人情報を必要とする場合も同様にいたします。
        </P>

        <h3>２．個人情報の利用および提供について </h3>
        <P>
          当院は、患者さんの個人情報の利用につきましては以下の場合を除き、本来の利用目的の範囲を超えて使用いたしません。
        </P>
        <ul>
          <li>患者さんの了解を得た場合</li>
          <li>個人を識別あるいは特定できない状態に加工¹して利用する場合</li>
          <li>法令等により提供を要求された場合</li>
        </ul>
        <P>
          当院は、法令の定める場合等を除き、患者さんの許可なく、その情報を第３者²に提供いたしません。
        </P>

        <h3>３．個人情報の適正管理について</h3>
        <P>
          当院は、患者さんの個人情報について、正確かつ最新の状態に保ち、患者さんの個人情報の漏えい、紛失、破壊、改ざん又は患者さんの個人情報への不正なアクセスを防止することに努めます。
        </P>

        <h3>４．個人情報の確認・修正等について</h3>
        <P>
          当院は、患者さんの個人情報について患者さんが開示を求められた場合には、遅滞なく内容を確認し、当院の「患者情報の提供等に関する指針」に従って対応いたします。また、内容が事実でない等の理由で訂正を求められた場合も、調査し適切に対応いたします。
        </P>

        <h3>５．問い合わせの窓口</h3>
        <P>
          当院の個人情報保護方針に関してのご質問や患者様の個人情報のお問い合わせは受付窓口および右記メールアドレス
          info@hirahata-clinic.or.jp にてお受けいたします。
        </P>

        <h3>６．法令の遵守と個人情報保護の仕組みの改善 </h3>
        <P>
          当院は、個人情報の保護に関する日本の法令、その他の規範を遵守するとともに、上記の各項目の見直しを適宜行い、個人情報保護の仕組みの継続的な改善を図ります。
        </P>

        <h3>7.臨床研究を行う場合について</h3>
        <P>
          臨床研究を行う場合、診療情報等の過去の情報のみを用いた研究や、手術検体試料を用いるような研究については、文部科学省と厚生労働省が定めた「人を対象とする医学系研究に関する倫理指針」（平成27年4月1日施行）に従い、「必ずしもインフォームド・コンセントを受けることを要しない」とされております。
          <br />
          当院では臨床研究について、診療情報から個人が特定される情報は削除した上で、学会やメディア等で発表されますが、その際にも個人が特定される情報は公表しません。ご自身の情報提供並びに拒否される方は遠慮なくお申し出ください。ご不明な点があれば担当医師にお尋ねください。
        </P>

        <div>
          <P>
            1
            単に個人の名前などの情報のみを消し去ることで匿名化するのではなく、あらゆる方法をもってしても情報主体を特定できない状態にされていること。
          </P>
          <P>
            2
            第３者とは、情報主体および受領者（事業者）以外をいい、本来の利用目的に該当しない、または情報主体によりその個人情報の利用の同意を得られていない団体または個人をさす。
          </P>
          <P>
            ※
            この方針は、患者さんのみならず、当院の職員および当院と関係のあるすべての個人情報についても上記と同様に取扱います。
          </P>
        </div>

        <h2>ヒラハタクリニックの個人情報の利用目的</h2>
        <h3>１．院内での利用</h3>
        <ol>
          <li>患者さんに提供する医療サービス</li>
          <li>医療保険事務</li>
          <li>会計・経理</li>
          <li>医療事故等の報告</li>
          <li>当該患者さんへの医療サービスの向上</li>
          <li>医療の質の向上を目的とした院内症例研究</li>
          <li>その他、患者さんに係る管理運営業務</li>
        </ol>
        <h3>２．院外への情報提供としての利用</h3>
        <ol>
          <li>他の病院、診療所、薬局、訪問看護ステーション、介護サービス事業者等との連携</li>
          <li>他の医療機関等からの照会への回答</li>
          <li>患者さんの診療等のため、外部の医師等の意見・助言を求める場合</li>
          <li>検体検査業務等の業務委託</li>
          <li>ご家族等への病状説明</li>
          <li>保険事務の委託</li>
          <li>審査支払機関へのレセプトの提供</li>
          <li>審査支払機関または保険者からの照会への回答</li>
          <li>事業者等から委託を受けた健康診断に係る、事業者等へのその結果通知</li>
          <li>医師賠償責任保険等に係る、医療に関する専門の団体や保険会社等への相談または届出等</li>
          <li>その他、患者さんへの医療保険事務に関する利用</li>
        </ol>
        <h3>３．その他の利用</h3>
        <ol>
          <li>医療・介護サービスや業務の維持・改善のための基礎資料</li>
          <li>外部監査機関への情報提供</li>
        </ol>

        <div>
          <P>
            1
            上記のうち、他の医療機関等への情報提供について同意しがたい事項がある場合には、その旨を担当窓口までお申し出ください。
          </P>
          <P>
            2 お申し出がないものについては、同意していただけたものとして取り扱わせていただきます。
          </P>
          <P>3 これらのお申し出は、後からいつでも撤回、変更等をすることができます。</P>
        </div>
        <div>
          <P>苦情、お問い合わせにつきましては、下記リンクよりお願い申し上げます。</P>
          <P
            css={`
              text-align: center;
            `}
          >
            <span
              css={`
                border: 3px solid #f7b13c;
                border-radius: 6px;
                background-color: #fffff0;
                padding: 0.5em 1em;
                margin: 1em;
                font-size: 1.25em;
              `}
            >
              <Link to="/contact">お問い合わせ</Link>
            </span>
          </P>
        </div>
      </div>
    </SubLayout>
  );
};

export default About;
